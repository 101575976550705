import { CookieSerializeOptions } from 'next/dist/server/web/types';

import { parseCookies, setCookie, destroyCookie } from 'nookies';

interface IUseCookiesData {
  readonly getItem: (name: string) => string;
  readonly removeItem: (name: string, options?: CookieSerializeOptions) => void;
  readonly setItem: (
    name: string,
    data: string,
    options?: CookieSerializeOptions
  ) => void;
}

export const useCookies = (): IUseCookiesData => {
  function getItem(name: string): string {
    const { [name]: item } = parseCookies();
    return item;
  }

  function setItem(
    name: string,
    data: string,
    options: CookieSerializeOptions = {
      path: '/',
    }
  ): void {
    setCookie(null, name, data, options);
  }

  function removeItem(
    name: string,
    options: CookieSerializeOptions = {
      path: '/',
    }
  ): void {
    destroyCookie(null, name, options);
  }

  return {
    getItem,
    setItem,
    removeItem,
  };
};
