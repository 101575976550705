import {
  createContext,
  useCallback,
  useState,
  useContext,
  FC,
  useEffect,
} from 'react';

import Router, { useRouter } from 'next/router';

import { destroyCookie } from 'nookies';

import { api } from '~/shared/services/api';
import { useCookies } from '~/shared/hooks/useCookies';
import { routes } from '~/shared/constants/routes';
import { cookies } from '~/shared/constants/cookies';

import { IAuthResponse } from '../interfaces/IAuthResponse';
import { IKdsApiCompanyConfiguration } from '../../board/interfaces/IKdsApiCompanyConfiguration';
import { IKdsApiAuthResponse } from '../../board/interfaces/IKdsApiAuthResponse';

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  configuration: IKdsApiCompanyConfiguration | null;
  token: string;
  setAuthData(authResponse: IAuthResponse): void;
  signIn(credentials: SignInCredentials): Promise<IAuthResponse>;
  signOut(): void;
}

export function signOutSSR(): void {
  destroyCookie(null, cookies.CONFIGURATION, { path: '/' });
  destroyCookie(null, cookies.CONFIGURATIONS, { path: '/' });
  destroyCookie(null, cookies.AUTH_TOKEN, { path: '/' });
  destroyCookie(null, cookies.DISPLAY, { path: '/' });
  destroyCookie(null, cookies.DISPLAY_STATUS_JOURNEY, { path: '/' });
  destroyCookie(null, cookies.COMPANY_CONFIGURATION_ID, {
    path: '/',
  });

  Router.push(routes.signIn);
}

const AuthContext = createContext({} as AuthContextData);

const AuthProvider: FC = ({ children }) => {
  const cookiesManager = useCookies();
  const router = useRouter();

  const [configuration, setConfiguration] =
    useState<IKdsApiCompanyConfiguration | null>(() => {
      const storagedConfiguration = cookiesManager.getItem(
        cookies.CONFIGURATION
      );

      if (storagedConfiguration) {
        return JSON.parse(storagedConfiguration);
      }

      return null;
    });

  const [token, setToken] = useState(() => {
    const storagedToken = cookiesManager.getItem(cookies.AUTH_TOKEN);

    api.defaults.headers.Authorization = `Bearer ${storagedToken}`;

    return storagedToken || '';
  });

  const setAuthData = useCallback((authResponse: IAuthResponse) => {
    setConfiguration(authResponse.configuration);
    setToken(authResponse.accessToken);
  }, []);

  const signIn = useCallback(
    async ({ email, password }): Promise<IAuthResponse> => {
      const encodedClientCredentials = Buffer.from(
        `${email}:${password}`
      ).toString('base64');

      const {
        data: {
          accessToken,
          user: {
            company: { _id: companyConfigurationId },
          },
        },
      } = await api.post<IKdsApiAuthResponse>(
        '/auth/signin',
        {
          grantType: 'user_credentials',
        },
        {
          headers: {
            authorization: `Basic ${encodedClientCredentials}`,
          },
        }
      );

      const { data: configurationResponse } =
        await api.get<IKdsApiCompanyConfiguration>(
          `/companies/${companyConfigurationId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

      return {
        accessToken,
        configuration: configurationResponse,
        companyConfigurationId,
      };
    },
    []
  );

  const signOut = useCallback(() => {
    cookiesManager.removeItem(cookies.CONFIGURATION);
    cookiesManager.removeItem(cookies.AUTH_TOKEN);
    cookiesManager.removeItem(cookies.DISPLAY);
    cookiesManager.removeItem(cookies.DISPLAY_STATUS_JOURNEY);
    cookiesManager.removeItem(cookies.COMPANY_CONFIGURATION_ID);
    setToken(null);
    setConfiguration(null);
    router.push(routes.signIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadConfiguration = useCallback(
    async (companyConfigId: string) => {
      const { data: configurationResponse } =
        await api.get<IKdsApiCompanyConfiguration>(
          `/companies/${companyConfigId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

      setConfiguration(configurationResponse);
    },
    [token]
  );

  useEffect(() => {
    const loggedIn = !!cookiesManager.getItem(cookies.AUTH_TOKEN);
    const companyConfigId = cookiesManager.getItem(
      cookies.COMPANY_CONFIGURATION_ID
    );

    if (loggedIn && companyConfigId) {
      reloadConfiguration(companyConfigId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        configuration,
        signIn,
        signOut,
        setAuthData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  return useContext(AuthContext);
}

export { AuthProvider, useAuth };
