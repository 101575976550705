import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    outline: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body, #__next {
    width: 100%;
    height: 100%;
    letter-spacing: 0.5px;
    font-weight: 400;
    overflow: auto;
  }

  #__next {
    z-index: 0;
    > main {
      height: 100%;
    }
  }


  h1, h2, h3, h4, h5, h6, strong, b {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.text};
    font-weight: 700;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
    font-weight: 700;
  }



  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  input[type=number] {
    -moz-appearance: textfield !important;
    appearance: textfield !important;
  }

  button:disabled {
    cursor: not-allowed;
  }

  input:disabled {
    background: ${({ theme }) => theme.colors.background};
  }

  *:focus:not(a) {
    box-shadow: none !important;
  }

  .react-select__menu {
    position: absolute;
    z-index: 100;
    border-radius: ${({ theme }) => theme.borderRadius.md};
  }

  .react-select__option {
    background: ${({ theme }) => theme.colors.white} !important;
    z-index: 200;

    &:hover {
      background: ${({ theme }) => theme.colors.primary}1a !important;
    }
  }

  .react-select__option--is-selected {
    background: ${({ theme }) => theme.colors.primary} !important;

    &:hover {
      background: ${({ theme }) => theme.colors.primary} !important;
    }
  }

  #nprogress .bar {
    background: ${({ theme }) => theme.colors.primary} !important;
    height: 3px;
  }

  #nprogress .peg {
    box-shadow: 0 0 10px ${({ theme }) => theme.colors.primary},
                0 0 5px ${({ theme }) => theme.colors.primary};
  }

  @media print {
    body {
      overflow: visible;
    }
  }
`;
