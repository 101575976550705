/* eslint-disable import/no-cycle */
import { parseCookies } from 'nookies';
import axios, { AxiosInstance } from 'axios';

import { signOutSSR } from '~/modules/auth/hooks/auth';

import { env } from '../constants/env';
import { cookies } from '../constants/cookies';

export function setupApi(ctx = undefined): AxiosInstance {
  const parsedCookies = parseCookies(ctx);
  const { API_URI } = env;

  const api = axios.create({
    baseURL: `${API_URI}/api`,
    headers: {
      ...(parsedCookies &&
        parsedCookies[cookies.AUTH_TOKEN] && {
          Authorization: `Bearer ${parsedCookies[cookies.AUTH_TOKEN]}`,
        }),
    },
  });

  api.interceptors.response.use(
    (success) => success,
    (error) => {
      if (error.response?.status === 401) {
        signOutSSR();
      }

      return Promise.reject(error);
    }
  );

  return api;
}

export const api = setupApi();

export const addBearerToken = (token: string): void => {
  api.defaults.headers.Authorization = `Bearer ${token}`;
};
