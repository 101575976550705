import {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';

import { useCookies } from '~/shared/hooks/useCookies';
import { cookies } from '~/shared/constants/cookies';

import { OrderStatusEnum } from '../enums/OrderStatusEnum';

interface IDisplayContextData {
  readonly display: string;
  readonly statusJourney: OrderStatusEnum[];
  readonly setDisplay: (display: string) => void;
  readonly setStatusJourney: (_statusJourney: OrderStatusEnum[]) => void;
}

const DisplayContext = createContext({} as IDisplayContextData);

const DisplayProvider: FC = ({ children }) => {
  const [display, setDisplay] = useState<string>();
  const [statusJourney, setStatusJourney] = useState<OrderStatusEnum[]>([]);
  const cookiesManager = useCookies();

  useEffect(() => {
    const cookiesDisplay = cookiesManager.getItem(cookies.DISPLAY);
    const cookiesStatusJourney = cookiesManager.getItem(
      cookies.DISPLAY_STATUS_JOURNEY
    );
    if (cookiesDisplay) {
      setDisplay(cookiesDisplay);
    }

    if (cookiesStatusJourney) {
      setStatusJourney(JSON.parse(cookiesStatusJourney) as OrderStatusEnum[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _setDisplay = useCallback((_display: string) => {
    setDisplay(_display);
  }, []);

  const _setStatusJourney = useCallback((_statusJourney: OrderStatusEnum[]) => {
    setStatusJourney(_statusJourney || []);
  }, []);

  return (
    <DisplayContext.Provider
      value={{
        display,
        statusJourney,
        setDisplay: _setDisplay,
        setStatusJourney: _setStatusJourney,
      }}
    >
      {children}
    </DisplayContext.Provider>
  );
};

const useDisplay = (): IDisplayContextData => {
  return useContext(DisplayContext);
};

export { DisplayProvider, useDisplay };
