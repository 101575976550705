const prefix = '@KDS';

export const cookies = {
  AUTH_TOKEN: `${prefix}.token`,
  DISPLAY: `${prefix}.display`,
  DISPLAY_STATUS_JOURNEY: `${prefix}.display-status-journey`,
  COMPANY_CONFIGURATION_ID: `${prefix}.company-configuration-id`,
  CONFIGURATIONS: `${prefix}.configurations`,
  NOTIFICATIONS_ACTIVE: `${prefix}.notification-active`,
  NOTIFICATIONS_VOLUME: `${prefix}.notification-volume`,
  CONFIGURATION: `${prefix}.configuration`,
  SELECTED_IN_HEADER: `${prefix}.selected-in-header`,
};
