import Head from 'next/head';
import { AppProps } from 'next/app';

import { ThemeProvider } from 'styled-components';
import { ChakraProvider } from '@chakra-ui/react';

import { DisplayProvider } from '~/modules/board/hooks/display';
import { AuthProvider } from '~/modules/auth/hooks/auth';

import { theme } from '~/shared/styles/theme';
import { GlobalStyle } from '~/shared/styles/global';
import { DefaultLayout } from '~/shared/layouts/DefaultLayout';
import { AuthLayout } from '~/shared/layouts/AuthLayout';

function MyApp({ Component, pageProps, router }: AppProps): JSX.Element {
  const Layout = router.pathname.includes('board') ? DefaultLayout : AuthLayout;

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="shortcut icon"
          href="/favicon.ico"
          sizes="any"
          type="image/x-icon"
        />
        <title>KDS | SERU</title>
      </Head>

      <ThemeProvider theme={theme}>
        <ChakraProvider>
          <GlobalStyle />

          <AuthProvider>
            <Layout>
              <DisplayProvider>
                {/* @ts-ignore */}
                <Component {...pageProps} />
              </DisplayProvider>
            </Layout>
          </AuthProvider>
        </ChakraProvider>
      </ThemeProvider>
    </>
  );
}

export default MyApp;
