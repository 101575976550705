import { FC } from 'react';

import Image from 'next/image';

import { env } from '~/shared/constants/env';

import {
  Container,
  LeftBox,
  RightBox,
  RightBoxContent,
  ImageContainer,
  LeftBoxPoweredByContainer,
  RightBoxPowredByContainer,
} from './styles';
import { PoweredByLogoImg } from '../../assets/images/powered-by-logo';
import signinBackgroundImg from '../../../../public/kds-fundo.png';

export const AuthLayout: FC = ({ children }) => {
  const PoweredBy: any = () => (
    <a href={env.RS_SOLUTIONS_SITE_URL} target="_blank" rel="noreferrer">
      <PoweredByLogoImg />
    </a>
  );

  return (
    <Container>
      <LeftBox>
        <ImageContainer>
          <Image
            src={signinBackgroundImg}
            alt="..."
            layout="fill"
            objectFit="cover"
            objectPosition="left bottom"
            quality={100}
            priority
          />
        </ImageContainer>

        <LeftBoxPoweredByContainer>
          <PoweredBy />
        </LeftBoxPoweredByContainer>
      </LeftBox>

      <RightBox>
        <RightBoxContent>
          {children}

          <RightBoxPowredByContainer>
            <PoweredBy />
          </RightBoxPowredByContainer>
        </RightBoxContent>
      </RightBox>
    </Container>
  );
};
