export const theme = {
  colors: {
    primary: '#38398f',
    onPrimary: '#FFF',
    secondary: '#3fc1a9',
    primaryDark: '#222252',
    onPrimaryDark: '#FFF',
    text: '#333',
    green: '#00a859',
    orange: '#fc7600',
    white: '#fff',
    grey: '#eee',
    darkGrey: '#ccc',
    red: '#f00',
    background: '#444',
    backdrop: 'rgba(0, 0, 0, 0.35)',
  },
  boxShadow: {
    sm: '3px 3px 15px #00000015',
    md: '3px 3px 15px #00000044',
  },
  borderRadius: {
    sm: '5px',
    md: '8px',
    lg: '12px',
    xlg: '25px',
    none: '0',
  },
  spacing: {
    xxs: '5px',
    xs: '12px',
    sm: '16px',
    md: '30px',
    xmd: '35px',
    lg: '40px',
    xl: '80px',
    '2xs': '20px',
  },
  breakpoints: {
    laptop: '1440px',
    tablet: '850px',
    mobile: '375px',
  },
};
