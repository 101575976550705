import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export interface EnviromentVariables {
  API_URI: string;
  RS_SOLUTIONS_SITE_URL: string;
}

export const env: EnviromentVariables = publicRuntimeConfig;
